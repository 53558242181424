
import "vue-inner-image-zoom/lib/vue-inner-image-zoom.css";
import InnerImageZoom from "vue-inner-image-zoom";
import { FormValidations } from "@/mixins/form-validations";
import { Navigation } from "@/mixins/navigation";
import { CustomDate } from "@/mixins/custom-date";
import Loader from "@/components/General/Loader.vue";
import { Notification } from "@/models/notification.interface";
import PaymentPlatforms from "@/components/Layout/Client/PaymentPlatforms.vue";
import CustomCryptoPayment from "@/components/Layout/Client/Payments/CustomCryptoPayment.vue";
import CustomTraditionalPayment from "@/components/Layout/Client/Payments/CustomTraditionalPayment.vue";
import BalancePayment from "@/components/Layout/Client/Payments/BalancePayment.vue";
import Component, { mixins } from "vue-class-component";
import { StyleCheck } from "@/mixins/style-check";
import { TermTypes } from "@/mixins/term-types";
import ConfirmCheckout from "@/components/Layout/Client/ConfirmCheckout.vue";
import { Product } from "@/models/product.interface";
@Component({
  components: {
    PaymentPlatforms,
    CustomCryptoPayment,
    CustomTraditionalPayment,
    BalancePayment,
    "inner-image-zoom": InnerImageZoom,
    Loader,
    ConfirmCheckout,
  },
})
export default class Checkout extends mixins(
  Navigation,
  CustomDate,
  FormValidations,
  StyleCheck,
  TermTypes
) {
  dialogLeaveCheckoutWithoutBill = false;
  makePayment = false;
  step = 1;
  quantity = 1;
  loading = false;
  selectedPaymentPlatform = {};
  loader = false;

  public currencyFilter(value) {
    return this.$options.filters?.currency(value);
  }

  public percentageFilter(value) {
    return this.$options.filters?.percent(value, 3);
  }

  private selectedPaymentPlatformChanged(value: any) {
    this.selectedPaymentPlatform = value;
  }

  private async created(): Promise<void> {
    if (this.checkoutInfo.resume) {
      this.loader = true;
      this.step = 2;
      await this.$store
        .dispatch("products/getProduct", this.checkoutInfo.product_id)
        .then(() => {
          window.scrollTo(0, 0);
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$t("Product.fetchError.productById", {
              name: this.checkoutInfo.product_id,
            }) as string,
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        });
      await this.$store
        .dispatch("checkout/getSingleBill", this.checkoutInfo.bill_id)
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("Views.checkout-error1"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
        });
      this.loader = false;
    }

    if (!this.checkoutInfo.on_checkout) {
      this.navigate("/home");
    }

    setInterval(() => {
      this.setReactiveCurrentDate();
    }, 1000);
  }

  private get image(): string {
    let logo = this.$store.getters["system/getLogo"];
    if (logo) {
      return logo.url;
    } else {
      return "";
    }
  }

  private async leaveCheckout() {
    const previous_route = this.checkoutInfo.previous_route;
    await this.$store.dispatch("checkout/setCheckoutInformation", {
      product_id: -1,
      previous_route: -1,
      on_checkout: false,
    });
    this.navigate(previous_route);
  }

  private get checkoutInfo() {
    return this.$store.getters["checkout/getCheckoutInformation"];
  }

  private get product(): Product {
    return this.$store.getters["products/getProduct"];
  }
  get confirmDetail() {
    return {
      name: this.product.name,
      units: this.quantity,
      total: this.currencyFilter(this.quantity * this.product.price),
      price: this.currencyFilter(this.product.price),
    };
  }
  get isProductActive() {
    return this.product.status?.name === this.$constants.STATUS.ACTIVE;
  }

  private get singleBill() {
    return this.$store.getters["checkout/getSingleBill"];
  }

  private get paymentFilesProgress() {
    return this.$store.getters["payment/getPaymentFilesProgress"];
  }

  private async confirmCheckout() {
    this.loading = true;

    await this.$store
      .dispatch("checkout/createBill", {
        id: this.checkoutInfo.product_id,
        quantity: this.quantity,
      })
      .then(async (response: any) => {
        await this.$store
          .dispatch("checkout/getSingleBill", response.id)
          .catch(() => {
            const Error: Notification = {
              message: this.$tc("Views.checkout-error2"),
              timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
              top: true,
              bottom: false,
              left: false,
              right: false,
              currentPath: this.$route.fullPath,
              error: true,
            };

            this.$store.dispatch("notifications/showNotification", Error);
          });

        const Success: Notification = {
          message: this.$tc("Views.checkout-success1"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: false,
        };

        this.$store.dispatch("notifications/showNotification", Success);
        this.step = 2;
      })
      .catch(() => {
        const Error: Notification = {
          message: this.$tc("Views.checkout-error2"),
          timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
          top: true,
          bottom: false,
          left: false,
          right: false,
          currentPath: this.$route.fullPath,
          error: true,
        };

        this.$store.dispatch("notifications/showNotification", Error);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  private paymentCreated(payment_id: string) {
    this.makePayment = false;
    this.loading = false;
    this.$store.dispatch("payment/resetPaymentFilesProgress");
    this.$router.push(
      "/investment/checkout/registered-payment/success/" + payment_id
    );
  }

  private formError() {
    this.makePayment = false;
    this.loading = false;
  }

  private get showStep() {
    if (this.product.check_users && this.singleBill.status !== undefined) {
      if (this.singleBill.status.name == this.$constants.STATUS.RESERVED) {
        return false;
      } else {
        return true;
      }
    } else if (
      this.product.investment &&
      this.product.investment.is_a_bucket &&
      this.singleBill.status !== undefined
    ) {
      if (
        this.singleBill.status.name == this.$constants.STATUS.WAITING_FOR_USERS
      ) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  get days() {
    return process.env.VUE_APP_BILL_DAYS || "";
  }
  get manualDays(): string | undefined {
    if (this.product.investment?.is_manual)
      return process.env.VUE_APP_RETURN_TIMESPAN_DAYS;
    return undefined;
  }
}
