
import { FormValidations } from "@/mixins/form-validations";
import Component, { mixins } from "vue-class-component";
import { Navigation } from "@/mixins/navigation";
import { Notification } from "@/models/notification.interface";
import { VueEditor } from "vue2-editor";
import { Prop, Watch } from "vue-property-decorator";
import { StyleCheck } from "@/mixins/style-check";
import { FileCheck } from "@/mixins/file-check";

@Component({
  components: {
    VueEditor,
  },
})
export default class CustomTraditionalPayment extends mixins(
  Navigation,
  FormValidations,
  StyleCheck,
  FileCheck
) {
  @Prop() paymentPlatform: any;
  @Prop({ default: false }) makePayment!: boolean;

  $refs!: {
    traditionalPayment: HTMLFormElement;
  };

  traditionalPayment = {
    confirmation_number: "",
    amount: 0,
    description: "",
    files: [],
  };

  @Watch("makePayment")
  private createTraditionalPayment(val) {
    if (val && this.$refs.traditionalPayment.validate()) {
      const payload = {
        payment_traditional: {
          transaction_id: this.traditionalPayment.confirmation_number,
          amount: this.traditionalPayment.amount,
          description: this.traditionalPayment.description,
        },
        bill: {
          id: this.singleBill.id,
        },
        platform: {
          id: this.paymentPlatform.id,
        },
      };
      this.$store
        .dispatch("payment/createTraditionalPayment", payload)
        .then(async (response) => {
          const payload = new FormData();
          payload.append(
            "DATA",
            JSON.stringify({
              bill: { id: this.singleBill.id },
              payment: { id: response.data.id },
            })
          );

          if (this.traditionalPayment.files.length > 0) {
            this.traditionalPayment.files.forEach((file) => {
              payload.append(
                this.$constants.FILE_CATEGORY.PAYMENT,
                file as File
              );
            });
          }

          await this.$store
            .dispatch("payment/addFilesForTraditionalPayment", payload)
            .catch(() => {
              const Error: Notification = {
                message: this.$tc("TraditionalPayments.vouchers-error"),
                timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
                top: true,
                bottom: false,
                left: false,
                right: false,
                currentPath: this.$route.fullPath,
                error: true,
              };

              this.$store.dispatch("notifications/showNotification", Error);
              this.$emit("paymentCreated", response.data.id);
            });

          const Success: Notification = {
            message: this.$tc("TraditionalPayments.success"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.SUCCESS,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            persistent: true,
            error: false,
          };

          this.$store.dispatch("notifications/showNotification", Success);
          this.$emit("paymentCreated", response.data.id);
        })
        .catch(() => {
          const Error: Notification = {
            message: this.$tc("TraditionalPayments.error"),
            timeout: this.$constants.NOTIFICATION_TIMEOUT.ERROR,
            top: true,
            bottom: false,
            left: false,
            right: false,
            currentPath: this.$route.fullPath,
            error: true,
          };

          this.$store.dispatch("notifications/showNotification", Error);
          this.$emit("formError");
        });
    } else {
      this.$emit("formError");
    }
  }

  private get checkoutInfo() {
    return this.$store.getters["checkout/getCheckoutInformation"];
  }

  private get singleBill() {
    return this.$store.getters["checkout/getSingleBill"];
  }
}
